import React, { useState,useEffect } from 'react';
import tw, { css } from 'twin.macro'

export const FeatureAccordion = ({items}) => {
  const [isExpanded, setIsExpanded] = useState({});
  

  const toggleExpand = (index ) => {
    setIsExpanded(prevState => {
      const newState =  { [index]: !prevState[index] };
      return newState;
    });
  };

  return (
    <>
    <ul tw='flex flex-col px-1 space-y-2 md:space-y-4 w-full'>
  {items.map((item, index) => (
    <li key={index} css={tw`flex flex-col px-4 md:px-7 text-xs`} 
    css={css`
            border-radius: 0 1.2rem 1.2rem 1.2rem;
            background-color: ${isExpanded[index]? 'white' : '#58C8DF'};
            color: ${isExpanded[index]? 'black' : 'white'};
            transition: background-color 0.6s ease-in-out, color 0.6s ease-in-out;
            `}
    >
      <p tw='flex items-center font-bold p-2'>
        <span
          tw='ml-2 p-2 rounded-full font-extrabold text-xl cursor-pointer text-center' 
          onClick={() => toggleExpand(index)}
        >
         {!isExpanded[index] ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#58C8DF" css={tw`rounded-full h-8 w-8 p-1 cursor-pointer bg-white`}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            :            
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" css={tw`rounded-full h-8 w-8 p-1 cursor-pointer bg-blue-500`}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 12h12" />
            </svg>   
          }
        </span>
        <img src={item.svg} alt={item.title} 
        css={css`
          height: ${isExpanded[index]? '0' : '40px'};
          width: ${isExpanded[index]? '0' : '40px'};
          overflow: hidden;
          transition: width 0.6s ease-in-out;
          display: inline-block;
          `}/>
        <span tw='text-xs ml-2'>{item.title}</span>
      </p>
      <div
        style={{
          maxHeight: isExpanded[index] ? '1000px' : '0',
          transition: 'max-height 0.5s ease-in-out',
          overflow: 'hidden'
        }}
      >
        <p tw='pl-16 pr-4 pb-5 text-xs text-black text-left'>{item.content}</p>
      </div>
    </li>
  ))}
</ul>
    </>
  )
}