import React, { useState,useEffect } from 'react';
import tw, { css, styled } from 'twin.macro'
import MaytronicsGray from '../../images/Maytronics_GREY.svg'
import MaytronicsLightBlue from '../../images/Maytronics_LghtBLUE.svg'
import XGray from '../../images/X_grey.svg'
import XBlue from '../../images/X_blue.svg'
import CheckGray from '../../images/Check_grey.svg'
import CheckBlue from '../../images/Check_blue.svg'
const WhyMaytronicsTable = ({}) => {
  const [isExpanded, setIsExpanded] = useState({});

  const toggleExpand = (index) => {
    setIsExpanded(prevState => {
      const newState = { [index]: !prevState[index] };
      return newState;
    });
  };
 

  const featuresList =[
    {
      feature: 'Industry Focus',
      canibuild: false,
      poolStudio: true,
      description: <><strong>Maytronics Build</strong> is specifically designed for pool and spa builders, providing tools tailored to the unique demands of the pool industry rather than broader markets.</>
    },
    {
      feature: 'Lead Qualification',
      canibuild: true,
      poolStudio: false,
      description: <>With <strong>Maytronics Build</strong>, pool builders can easily qualify leads using precise site analysis and customer insights, ensuring the best use of resources compared to less targeted systems. </>
    },
    {
      feature: '3D Visualisation',
      canibuild: true,
      poolStudio: true,
      description: <><strong>Maytronics Build</strong> enables pool builders to create accurate, realistic 3D renderings that showcase the proposed final pool design. This tool helps builders effectively communicate their vision to clients, inspires creativity, builds confidence in the design process, and ensures that expectations are met from start to finish.</>
    },
    {
      feature: 'Customisation',
      canibuild: false,
      poolStudio: true,
      description: <><strong>Maytronics Build</strong> offers customisation for quotes, contracts, and customer presentations, ensuring a tailored professional approach surpassing other platforms' limited options.</>
    },
    {
      feature: 'Quoting Speed',
      canibuild: true,
      poolStudio: false,
      description: <><strong>Maytronics Build</strong> delivers quotes in seconds, streamlining the sales process faster than manual quotes and competitors, making it ideal for on-site and remote use.</>
    },
    {
      feature: 'Ease of Use',
      canibuild: true,
      poolStudio: false,
      description: <>Built for simplicity and efficiency, <strong>Maytronics Build</strong> ensures an intuitive user experience that doesn’t require advanced design skills or technical expertise. </>
    },
    {
      feature: 'Integration with Other Tools',
      canibuild: true,
      poolStudio: false,
      description: <><strong>Maytronics Build</strong> integrates seamlessly with essential tools and services, like site approvals and customer-facing galleries, offering a more complete service than competitors.</>
    },
    {
      feature: 'Cost-Effective',
      canibuild: false,
      poolStudio: false,
      description: <>Providing unparalleled value through advanced features tailored to pool builders, <strong>Maytronics Build</strong> ensures cost-effectiveness with one monthly subscription for unlimited users, while eliminating the need for additional software.</>
    }
  ]
    
    return (
        <div tw='w-full max-w-screen-lg overflow-x-auto px-6 py-4 md:px-4 text-center'>
      <table tw='w-full md:w-2/3 mx-auto divide-white border-white divide-y-2 divide-x-2 border-2 text-sm'>
      <thead tw='border-2 border-white'>
    <tr tw='text-xs md:text-sm'>
      <th tw='border-2 border-white bg-blue-500 py-5 w-2/5'>Feature</th>
      <th tw='border-2 border-white bg-blue-500 py-5 px-1 w-1/5'>Maytronics Build</th>
      <th tw='border-2 border-white bg-blue-500 py-5 w-1/5'>Canibuild</th>
      <th tw='border-2 border-white bg-blue-500 py-5 w-1/5'>Pool Studio</th>
    </tr>
  </thead>
  <tbody  tw='text-xs md:text-sm'>
  {
  featuresList.map((feature, index) => (
    <React.Fragment key={index}>
      <tr tw='divide-x-2' css={css`
        background-color: ${isExpanded[index]? 'white' : index % 2 === 0 ? '#A9DDEC' : '#171E2E'};
        color: ${isExpanded[index]? 'black' : index % 2 === 0 ? 'black' : '#58C8DF'};
        transition: background-color 0.6s ease-in-out;
        `}>
        <td tw='border border-white py-4 w-2/5 relative'>
          <span tw='absolute -left-3 md:-left-4 top-0 h-full flex flex-col items-center justify-center'
          onClick={() => toggleExpand(index)}
          >
            { !isExpanded[index] ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#58C8DF" css={tw`rounded-full h-5 w-5 md:h-8 md:w-8 p-1 cursor-pointer bg-white`}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            :            
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" css={tw`rounded-full h-5 w-5 md:h-8 md:w-8 p-1 cursor-pointer bg-blue-500`}>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 12h12" />
            </svg>   
          }
          </span>
          <p>{feature.feature}</p>
        </td>
        <td tw='border border-white py-2 w-1/5'>
        <div tw='w-full flex justify-center'>
        {isExpanded[index]? 
        <img src={MaytronicsGray} alt="Maytronics" tw='h-6 w-6 md:h-8 md:w-8' /> : 
        index % 2 === 0 ? <img src={MaytronicsGray} alt="Maytronics" tw='h-6 w-6 md:h-8 md:w-8' /> : 
        <img src={MaytronicsLightBlue} alt="Maytronics"  tw='h-6 w-6 md:h-8 md:w-8'/>
        }
        </div>
        </td>
        <td tw='border border-white py-2 md:py-3 w-1/5'>
        <div tw='w-full flex justify-center'>
        {feature.canibuild ? isExpanded[index]? 
        <img src={CheckGray} alt="Maytronics" tw='h-4 w-4 md:h-6 md:w-6' /> : 
        index % 2 === 0 ? <img src={CheckGray} alt="Maytronics" tw='h-4 w-4 md:h-6 md:w-6' /> : 
        <img src={CheckBlue} alt="Maytronics"  tw='h-4 w-4 md:h-6 md:w-6'/>
         : 
         isExpanded[index]? 
        <img src={XGray} alt="Maytronics" tw='h-5 w-5 md:h-6 md:w-6' /> : 
        index % 2 === 0 ? <img src={XGray} alt="Maytronics" tw='h-5 w-5 md:h-6 md:w-6' /> : 
        <img src={XBlue} alt="Maytronics"  tw='h-5 w-5 md:h-6 md:w-6'/>
         }         
        </div>
         </td>
        <td tw='border border-white py-2 md:py-3 w-1/5'>
        <div tw='w-full flex justify-center'>
          {feature.poolStudio ? isExpanded[index]? 
        <img src={CheckGray} alt="Maytronics" tw='h-4 w-4 md:h-6 md:w-6' /> : 
        index % 2 === 0 ? <img src={CheckGray} alt="Maytronics" tw='h-4 w-4 md:h-6 md:w-6' /> : 
        <img src={CheckBlue} alt="Maytronics"  tw='h-4 w-4 md:h-6 md:w-6'/>
         : 
         isExpanded[index]? 
        <img src={XGray} alt="Maytronics" tw='h-5 w-5 md:h-6 md:w-6' /> : 
        index % 2 === 0 ? <img src={XGray} alt="Maytronics" tw='h-5 w-5 md:h-6 md:w-6' /> : 
        <img src={XBlue} alt="Maytronics"  tw='h-5 w-5 md:h-6 md:w-6'/>
        }
        </div>
        </td>
      </tr>
      <tr    >
        <td tw='border-0 bg-white text-left font-semibold' colSpan={4}
        > 
        <p 
        style={{
          padding: `${isExpanded[index] ? '20px' : '0'} 20px`,
          maxHeight: isExpanded[index] ? '1000px' : '0',
          overflow: 'hidden',
          transition: 'max-height 0.6s ease-in-out, padding 0.8s ease-in-out',
          ...(typeof window !== 'undefined' && window.innerWidth <= 1024 && {
            padding: `${isExpanded[index] ? '16px' : '0'} 16px`,
          }),
        }}>
        {feature.description}
        </p>
        </td>
      </tr>
    </React.Fragment>
  ))
}
  </tbody>
</table>
       </div>
    )
}

export default WhyMaytronicsTable
