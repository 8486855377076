import React from 'react';
import './swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import Img from 'gatsby-image'
import 'swiper/swiper.min.css';
import './nav.css';
export const SwiperComponent = ({ data }) => {
SwiperCore.use([Navigation]);
  const slides = data.map((item, index) => {
    const {text, img, footer} = item;
    return (
      <SwiperSlide key={`slide-${index}`} style={{ boxSizing: 'border-box' }}>
  <div tw='h-full w-full flex flex-col' css={css`
      aspect-ratio: 1 / 1; 
      height: auto; 
      max-width: 100%; 
      `}>
    <div tw='h-full w-full'>
      <Img {...img} tw="w-full h-full flex" loading="eager" 
        css={css`
          img {
            object-fit: contain !important;
            border-radius: 1.2rem;
            overflow: hidden;
          }
        `}
      />
    </div>
  </div>
</SwiperSlide>
    );
  });

  return (
    <Swiper
  centeredSlides={true}
  loop={true}
  style={{ width: '100%', height: '100%', overflow: 'hidden' }}
  breakpoints={{
    0: {
      slidesPerView: 1,
      spaceBetween: 8,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 18,
    },
  }}
  navigation
  speed={1000}
  css={css`
  .swiper-button-next, .swiper-button-prev {
  top: calc(20%) !important;
}
  `}
>
      {slides}
    </Swiper>
  );
};

SwiperComponent.propTypes = {
	data: PropTypes.objectOf(PropTypes.array),
	}

